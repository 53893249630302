.bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
}

.imageContainer {
    margin: 40px 0 40px 0;
    max-width: 250px;
}

.actionButton {
    text-decoration: none;
    background-color: #031d8a;
    color: #ffffff;
    padding: 10px 15px;
    margin-top: 20px;
}

.text {
    margin-top: 20px;
}