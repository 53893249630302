.tablePanel {
    display: inline-block;
    background-color: #fff;
}

.tableContainer {
    display: table;
    max-width: 900px;
    border-collapse: collapse;
    float: left;
}

.tableContainer tr {
    border-bottom: 1px solid #e3e3e3;
}

.tableContainer tr:first-child {
    border-top: 1px solid #e3e3e3;
}

.tableContainer td {
    height: 30px;
    display: table-cell;
    vertical-align: top;
    padding: 15px 25px;
    border: none;
}