.seminarFileContainer {
    border-bottom: 1px solid #031d8a;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.imageContainer {
    padding-left: 20px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.seminarFileContainer p {
    padding-top: 10px;
    padding-left: 20px;
    align-items: center;
    vertical-align: middle;
}

.downloadContainer {
    margin-left: auto;
    display: flex;
    margin-top: 10px;
    margin-right: 50px;
    margin-bottom: 10px;
}

.downloadContainer button {
    text-decoration: none;
    background-color: #031d8a;
    color: #ffffff;
    padding: 8px 10px;
}

.downloadButtonContainer {
    height: 30px;
}

.imageContainer {
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerOne {
    padding-left: 100px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerTwo {
    padding-left: 140px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerThree {
    padding-left: 180px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}


/* ////////////////////////////////////////// Dark Style //////////////////////////// */

.eventDocumentContainerDark {
    background-color: #001559;
    color: #f0f1f5;
    border-bottom: 1px solid #f0f1f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.eventDocumentContainerDark p {
    color: #f0f1f5;
}


/* ////////////////////////////////////////// Grey Style //////////////////////////// */

.eventDocumentContainerGrey {
    background-color: #f0f1f5;
    color: #001559;
    border-bottom: 1px solid #001559;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.eventDocumentContainerGrey p {
    color: #001559;
}

@media (max-width: 768px) {
    .eventDocumentContainerDark, .eventDocumentContainerGrey{
        flex-direction: column;
    }

    .downloadContainer {
        margin: 10px auto;
    }

    .imageContainer {
        padding-left: 0;
    }
}