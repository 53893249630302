.button {
    composes: border-box from '../../assets/css/helpers.css';
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-weight: var(--font-weight-regular);
    height: 48px;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0 48px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition-duration: var(--transition-duration);
    transition-property: background-color border-color box-shadow;
    transition-timing-function: ease-in-out;
}

.button:hover {
    box-shadow: 0 0 10px 4px var(--shadow);
}

.button:focus {
    box-shadow: none;
    outline: 2px dotted orange;
}

.button[disabled],
.button[disabled]:hover {
    background-color: var(--gray-light-soft);
    border-color: var(--gray-light-soft);
    box-shadow: none;
    color: var(--gray-medium-soft);
    cursor: not-allowed;
}

.right-icon,
.rightIcon {
    height: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
}