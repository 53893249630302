.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
}