.calendarPanel {
    @media (min-width: 768px);
    padding-right: 15px;
    padding-left: 15px;
    background-color: #001559;
}

.calendarPanel {
    width: 100%;
    color: #474747;
    display: table;
    border: none;
    overflow-x: auto;
    height: auto;
    border-collapse: collapse;
}

.calendarRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 25px;
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    cursor: pointer;
}

.calendarRow:hover {
    background-color: #031d8a;
}

.calendarLink {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
}

.calendarBlock {
    display: flex;
    flex-wrap: wrap;
    margin-right: 2rem;
    min-width: 7rem;
}

.calendarDate {
    color: #b5b9c7;
    font-family: "PT Serif", serif;
    font-size: 44px;
    line-height: 40px;
}

.calendarMonth {
    /* display: grid;
    grid-template-columns: auto; */
    color: #b5b9c7;
    font-family: "PT Serif", serif;
    font-size: 14px;
}

.calendarTitle {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 22px;
}

.calendarPlace {
    font-size: 14px;
    font-family: "PT Serif", serif;
    display: block;
    color: #b5b9c7;
}

.calendarDetailType {
    margin-left: auto;
    padding-right: 30px;
}

.calendarDetailTypeText {
    padding: 5px 20px;
    font-size: 14px;
    left: 300px;
    border: 1px solid #fff;
    opacity: .5;
    border-radius: 4px;
    color: #fff;
}

.calendarSpacer {
    max-width: 550px;
    height: 20px;
    /* background-color: #fff; */
}