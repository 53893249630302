/* ////////////////////////////////Common Styles///////////////////////////// */

/* .messageBarContainer {
    width: 50%;
} */

.messageBarComponents {
    display: flex;
    flex-direction: row;
}


/* //////////////////////////////// Message Bar Color Styles ///////////////////////////// */

.messageBarRed {
    background-color: #FAE4E6;
    padding: 10px;
}

.messageBarGreen {
    background-color: #DFF4DA;
    padding: 10px;
}

.messageBarOrange {
    background-color: #FEF2CC;
    padding: 10px;
}

.messageBarGrey {
    background-color: #F1F0EF;
    padding: 10px;
}

.messageBarTransparent {
    background-color: transparent;
    padding: 10px;
}