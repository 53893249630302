.visually-hidden,
.sr-only {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.txt-bold {
  font-weight: 600;
}

.border-box {
  box-sizing: border-box;
}

.border-box *,
.border-box *::after,
.border-box *::before {
  box-sizing: inherit;
}
