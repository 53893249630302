.right {
  transform: rotate(0deg);
}

.left {
  transform: rotate(180deg);
}

.up {
  transform: rotate(-90deg);
}

.down {
  transform: rotate(90deg);
}
