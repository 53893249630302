.page {
    height: 100vh;
}

.imageContainer {
    margin-top: 10px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
}

.image {
    max-width: 325px;
    width: 75vw;
}

.title {
    display: none;
}

.spacer {
    height: 65px;
}