.seminarFileContainer {
    border-bottom: 1px solid #031d8a;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.imageContainer {
    padding-left: 20px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.seminarFileContainer p {
    padding-top: 10px;
    padding-left: 20px;
    align-items: center;
    vertical-align: middle;
}

.uploadContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 50px;
    margin-bottom: 10px;
}

.uploadButtonContainer {
    height: 30px;
    width: 70px;
}

.imageContainerZero {
    padding-left: 20px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerOne {
    padding-left: 60px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerTwo {
    padding-left: 100px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

.imageContainerThree {
    padding-left: 140px;
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .seminarFileContainer {
        flex-direction: column;
        align-items: flex-start;
    }
}