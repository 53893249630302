.registrationContainer {
    padding-left: 280px;
    padding-right: 280px;
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 75px;
}

.ifaLogoImage {
    height: 45px;
    padding-bottom: 20px;
}

.registrationContainer form {
    margin-left: 20px;
    margin-right: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #031d8a;
    justify-content: center;
}

.registrationForm {
    border: 1px solid #031d8a;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
}

.searchInput {
    height: 30px;
    border: 1px solid #031d8a;
    width: 100%;
    margin-bottom: 30px;
}

.submitButton {
    margin-top: 30px;
    background-color: #031d8a;
    color: #ffffff;
    margin-left: 20px;
    padding: 8px 30px;
}

@media (max-width: 992px) {
    .registrationContainer {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px) {
    .registrationContainer {
        padding-left: 20px;
        padding-right: 20px;
    }
}

