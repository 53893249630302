.pageheadercontainerZero {
    display: flex;
    flex-direction: row;
    margin-right: 280px;
}

.pageheadercontainerZero h1 {
    font-size: 42px;
    line-height: 50px;
    font-family: "PT Serif", serif;
    color: #001559;
    margin-left: 280px;
}

.pageheadercontainerOne {
    display: flex;
    flex-direction: row;
}

.pageheadercontainerOne h1 {
    display: flex;
    flex-direction: row;
    font-size: 42px;
    line-height: 50px;
    font-family: "PT Serif", serif;
    color: #001559;
}

.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
}

.newslettersContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.uploadContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 50px;
    margin-bottom: 10px;
}

.uploadButtonContainer {
    height: 30px;
    width: 70px;
}

@media (max-width: 1280px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }

    .pageheadercontainerZero {
        margin-right: 0;
    }

    .pageheadercontainerZero h1{
        margin-left: 20px;
    }
}

@media (max-width: 767px) {
    .newslettersContainer {
        flex-direction: column;
    }

    .pageheadercontainerZero, .pageheadercontainerOne {
        flex-direction: column;
    }
}