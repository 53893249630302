.eventDetailsContainer {
    padding: 30px;
    background-color: #f0f1f5;
    max-width: 350px;
    min-width: 200px;
    margin-bottom: 30px;
}

.eventDetailsContainer h2 {
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainer table td {
    display: table-cell;
    padding: 5px 0;
}

.eventDetailsLabel {
    display: inline-block;
    width: 100px;
    color: #001559;
    font-weight: bold;
}

.eventDetailsContainer hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}