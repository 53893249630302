.callToActionContainer {
    background-color: #001559;
    padding-top: 5.71429rem;
    padding-bottom: 5.71429rem;
    padding-right: 280px;
    padding-left: 280px;
}

.callToActionPanel {
    display: flex;
    align-items: center;
}

.callToActionPanel h3 p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
    color: #fff;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 30px;
    text-align: center;
}

.callToActionLink {
    text-decoration: none;
}

.callToActionButton {
    display: flex;
    margin-top: 0;
    margin-left: 15px;
    font-size: 20px;
}

.callToActionButtonText {
    background-color: #fff;
    padding: 17px;
    color: #031d8a;
    font-weight: 500;
    line-height: 20px;
    display: block;
    text-decoration: none;
}

.callToActionButtonIcon {
    display: block;
    background-color: #ebedf6;
    padding: 17px;
    color: #031d8a;
    font-weight: 500;
    line-height: 20px;
}

@media (max-width: 992px) {
    .callToActionContainer {
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media (max-width: 768px) {
    .callToActionPanel {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .callToActionContainer {
        padding-right: 20px;
        padding-left: 20px;
    }
}