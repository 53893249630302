body {
  margin: 0;
  padding: 0;
  color: var(--gray-dark-hard);
  background: var(--page-bg);
  font-family: var(--default-font-family);
  font-size: 100%;
  font-weight: 300;
  line-height: 1.5;
}

* {
  letter-spacing: 0.33px;
}

img {
  max-width: 100%;
}

select,
input {
  border: 0;
  border-radius: 0;
  outline-width: 0;
}

button {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  background: none;
  border-radius: 0;
  cursor: pointer;
}

button[disabled] {
  color: rgb(0, 0, 0);
  background: #ccc;
}

svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

a:focus {
  outline: 0;
  box-shadow: 0 0 3px 2px #0265de99;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-regular);
}

h1 {
  font-size: var(--font-size-title);
}

h2 {
  font-size: var(--font-size-sub-title);
  font-weight: var(--font-weight-semi-bold);
}

h3 {
  font-size: var(--font-size-sub-title);
  font-weight: var(--font-weight-semi-bold);
}

h4 {
  font-size: var(--font-size-large);
}

h5 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
}

h6 {
  font-size: 100%;
}

p {
  margin: 4px 0 8px;
  font-size: 100%;
  font-weight: 200;
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--default-font-family);
}

progress {
  appearance: none;
  background-color: #eee;
}

progress::-webkit-progress-bar {
  background: #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
}

progress::-webkit-progress-value {
  background-color: var(--primary-color);
}

progress::-moz-progress-bar {
  background-color: var(--primary-color);
}
