.pageheaderContainerLight h1 {
  font-size: 42px;
  line-height: 50px;
  font-family: 'PT Serif', serif;
  color: #001559;
}

.pageheaderContainerDark h1 {
  font-size: 42px;
  line-height: 50px;
  font-family: 'PT Serif', serif;
  background-color: #001559;
  color: #ffffff;
}
