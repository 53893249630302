.newsarticlecontainer {
    margin: 50px;
}

.newsarticlecontainer img {
    height: auto;
    max-width: 300px;
}

.newsarticlecontainer h3 {
    color: #111 !important;
}

.newsarticlecontainer p {
    max-width: 300px;
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: .85714rem;
    line-height: 30px;
}

.btnprimary {
    display: flex;
    align-items: center;
}

.btnprimarydesign {
    color: #031d8a;
}

.btnprimarytext {
    background-color: transparent;
    color: #031d8a;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    border-width: 0;
    color: #031d8a;
    text-decoration: none;
}

.btnprimaryicon {
    color: #031d8a;
    font-size: 17px;
    line-height: 30px;
}

.btnprimaryicon svg {
    width: 27px;
    height: 10px;
}

.btnprimary .btnprimaryicon {
    padding-left: 20px;
    display: inline-block;
    background-color: transparent;
}

@media (max-width: 992px) {
    .newsarticlecontainer {
        margin: 50px 0 50px 30px;
    }
}

@media (max-width: 992px) {
    .newsarticlecontainer {
        margin: 50px 0 50px 0px;
    }
}