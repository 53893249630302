.eventDetailsContainer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 58.333333%;
}

.eventDetailsContainer h1 {
    font-family: "PT Serif", serif;
    color: #001559;
    font-size: 42px;
}

.eventDetailsContainer p {
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
}