.formContainer {
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
}

.afi-SignIn-wrapper {
    justify-content: center;
    border: 1px solid;
    background-color: var(--page-bg);
    margin-top: 12px;
    max-width: 400px;
    overflow: hidden;
    padding: 64px 24px 24px;
    text-align: center;
    width: 68%;
}

.afi-SignIn-form {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.afi-SignIn-field {
    margin-bottom: 36px;
    max-width: 400px;
    width: 100%;
}

.afi-SignIn-remember-me {
    margin-bottom: 72px;
}

.afi-SignIn-btn {
    display: block;
    margin-bottom: 12px;
    max-width: 400px;
    width: 100%;
}

.afi-SignIn-forgotPassword {
    display: block;
    margin-bottom: 32px;
    text-decoration: none;
    text-align: center;
}

.afi-SignIn-forgotPassword:hover {
    text-decoration: underline;
}

.afi-SignIn-no-account {
    display: block;
    margin-bottom: 8px;
}

@media (max-width: 768px) {
    .formContainer {
        margin: 50px 0;
    }
}