.afi-sg-group {
  padding: 50px;
}

.afi-sg-section-title {
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.afi-sg-demo {
  border: 1px solid #333;
  min-height: 100px;
  width: 80vw;
}

.afi-sg-demo-frame {
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  min-height: 667px;
  min-width: 375px;
  position: relative;
}

.afi-sg-demo-list {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  padding-left: 0;
}

.afi-sg-demo-list--horizontal {
  align-content: center;
  flex-flow: row wrap;
}

.afi-sg-demo-list-item {
  margin-bottom: 10px;
}

.afi-sg-demo-list--horizontal .afi-sg-demo-list-item {
  margin-right: 10px;
}

.afi-sg-icon {
  height: 32px;
  width: 32px;
}

.position-relative {
  position: relative;
}

.afi-sg-code {
  background-color: #fff5ec;
  border-radius: 3px;
  color: var(--text-dark);
  line-height: 1.2;
  padding: 2px 4px;
}
