.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
}

.headercontainer {
    padding-top: 65px;
    padding-left: 65px;
}

.bodycontainer {
    padding-left: 65px;
    padding-top: 0px;
}

.aboutcontainer {
    background-color: #f0f1f5;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 280px;
    padding-right: 280px;
}

.aboutrow {
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: #f0f1f5;
    padding-bottom: 50px;
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
    
    .aboutcontainer {
        padding-left: 40px;
        padding-right: 40px;
    }
}