.root {
    position: relative;
}

.bg {
    color: white;
    height: 530px;
    overflow: hidden;
    padding: 0 0 1.875rem 0;
    position: fixed;
    text-align: center;
    width: 100vw;
    z-index: 0;
}

.bg::before {
    background-color: #001559;
    border-radius: 150vw;
    bottom: 0;
    content: '';
    display: block;
    height: 600vw;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 300vw;
    z-index: 1;
}

.small-header .bg {
    display: none;
}

.header {
    position: relative;
    z-index: 2;
    /* align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-top: 54px; */
}

.logo {
    position: relative;
}

.logo img {
    max-width: 320px;
}

.title {
    color: var(--white);
    font-size: var(--font-size-title);
    line-height: 1.25;
}

.body {
    /* align-items: center; */
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    z-index: 1;
}