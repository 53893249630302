.calendarPanel {
    @media (min-width: 768px) padding-right: 15px;
    padding-left: 15px;
    background-color: #001559;
}

.calendarPanel h2 {
    font-family: "PT Serif", serif;
    color: #fff;
    line-height: 1.4;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.calendarPanel table {
    width: 100%;
    color: #474747;
    display: table;
    border: none;
    overflow-x: auto;
    height: auto;
    border-collapse: collapse;
}

.calendarPanel table tr {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.calendarPanel table tr:first-child {
    border-top: none;
}

.calendarRow {
    cursor: pointer;
}

.calendarRow:hover {
    background-color: #031d8a;
}

.calendarBlock {
    display: flex;
    flex-wrap: wrap;
    margin-right: 2rem;
    min-width: 7rem;
}

.calendarDate {
    color: #b5b9c7;
    font-family: "PT Serif", serif;
    font-size: 44px;
    line-height: 40px;
}

.calendarMonth {
    /* display: grid;
    grid-template-columns: auto; */
    color: #b5b9c7;
    font-family: "PT Serif", serif;
    font-size: 14px;
}

.calendarDetail {}

.calendarTitle {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 22px;
    text-decoration: none;
}

.calendarPlace {
    font-size: 14px;
    font-family: "PT Serif", serif;
    display: block;
    color: #b5b9c7;
}

.calendarDetailType {
    margin-left: auto;
    padding-right: 30px;
}

.calendarDetailTypeText {
    padding: 5px 20px;
    font-size: 14px;
    left: 300px;
    border: 1px solid #fff;
    opacity: .5;
    border-radius: 4px;
    color: #fff;
}

.calendarSpacer {
    width: 550px;
    height: 20px;
    /* background-color: #fff; */
}

.btnprimary {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.btnprimarytext {
    background-color: transparent;
    color: #ffffff;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    border-width: 0;
    text-decoration: none;
}

.btnprimaryicon {
    color: #031d8a;
    font-size: 17px;
    line-height: 30px;
}

.btnprimaryicon svg {
    width: 27px;
    height: 10px;
    color: white;
}

.btnprimary .btnprimaryicon {
    padding-left: 20px;
    display: inline-block;
    background-color: transparent;
}