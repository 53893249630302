.header {
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

.logo {
  height: 100px;
}

.buttonsGroup section:nth-child(odd) {
  background-color: rgb(250, 250, 250);
  padding: 16px;
  border: 1px solid #ccc;
  border-width: 1px 0;
}

.content section {
  margin: 20px 0;
}

.contentList {
  list-style-type: none;
  display: flex;
  margin: 20px 0;
  padding: 0;
}

.contentList ul {
  list-style-type: none;
}

.contentList ul li {
  margin-bottom: 16px;
}

.inlineList {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  padding: 0;
}

.inlineList li {
  margin-right: 16px;
  margin-bottom: 16px;
}

.contentListItem {
  margin: 10px;
}

.content details {
  margin: 50px;
}

.content summary {
  cursor: pointer;
  margin: 50px 0;
  border-bottom: 1px solid #8a8a8a;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-color);
  background: var(--gray-light-soft);
  border-radius: 14px;
  padding: 16px;
}

.content summary summary {
  color: var(--gray-medium-soft);
}

.content details details {
  margin: 0;
  display: inline-block;
}

.component {
  margin-bottom: 40px;
}

.componentContainer {
  padding: 8px;
}

.inlineCode {
  background-color: #eee8d5;
  border: 1px solid #ddd6c1;
  border-radius: 3px;
  color: #c84200;
  padding: 0 3px;
}

.list li {
  margin-bottom: 15px;
}

.buttonsGroup section {
  margin: 20px 0 50px;
}
