.button {
  border-width: 1px;
  border-style: solid;
  color: var(--white);
}

.primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.primary:hover,
.primary:active {
  border-color: var(--primary-color-hover);
  color: var(--primary-color-hover);
}

.default {
  border-color: var(--gray-dark-hard);
  color: var(--gray-dark-hard);
}

.default:hover,
.default:active {
  border-color: var(--gray-dark-soft);
  color: var(--gray-dark-soft);
}

.maintain {
  border-color: var(--maintain-color);
  color: var(--maintain-color);
}

.maintain:hover,
.maintain:active {
  border-color: var(--maintain-color-hover);
  color: var(--maintain-color-hover);
}

.plan {
  border-color: var(--plan-color);
  color: var(--plan-color);
}

.plan:hover,
.plan:active {
  border-color: var(--plan-color-hover);
  color: var(--plan-color-hover);
}

.manage {
  border-color: var(--manage-color);
  color: var(--manage-color);
}

.manage:hover,
.manage:active {
  border-color: var(--manage-color-hover);
  color: var(--manage-color-hover);
}

.motivate {
  border-color: var(--motivate-color);
  color: var(--motivate-color);
}

.motivate:hover,
.motivate:active {
  border-color: var(--motivate-color-hover);
  color: var(--motivate-color-hover);
}

.expenditure {
  border-color: var(--expenditure-color);
  color: var(--expenditure-color);
}

.expenditure:hover,
.expenditure:active {
  border-color: var(--expenditure-color-hover);
  color: var(--expenditure-color-hover);
}

.danger {
  border-color: var(--afirefi-red);
  color: var(--afirefi-red);
}

.danger:hover,
.danger:active {
  opacity: 0.8;
}

.buttonIcon {
  composes: rightIcon from './BaseButton.module.css';
}
