.signUpRequestForm {
    display: flex;
    flex-direction: column;
    width: 80%;
    background: var(--white);
    margin: 50px auto 0 auto;
    padding: 50px;
    border: 1px solid #031d8a;
}

.signUpRequestForm h3 {
    color: var(--primary-color);
    margin-bottom: 30px;
}

.formContent {
    display: flex;
}

.formSectionOne {
    width: 50%;
}

.formSectionTwo {
    width: 50%;
}

.formComponent {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.signUpRequestFormContainer {
    margin-left: 20px;
    margin-right: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #031d8a;
    justify-content: center;
}

.searchInput {
    height: 30px;
    border: 1px solid #031d8a;
    width: 80%;
}

.searchInputTwo {
    height: 295px;
    border: 1px solid #031d8a;
    width: 80%;
}

.messageBarError {
    width: 80%;
}

.btnSubmit {
    margin-top: 30px;
}

.messageBarSuccess {
    width: 80%;
    margin: 10px 0 30px 0;
}

#grecaptcha-badge { visibility: hidden; }

@media (max-width: 768px) {
    .formContent {
        flex-direction: column;
    }

    .formSectionOne, .formSectionTwo, .searchInput, .searchInputTwo {
        width: 100%;
    }
}

