.color {
  min-width: 185px;
  text-align: center;
}

.color dd {
  margin: 0 0 4px;
}

.color-value {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.color-value span {
  color: var(--black);
}

.color-value::before {
  background-color: currentColor;
  border: 1px solid var(--gray-light-hard);
  content: '';
  display: block;
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

.colorBlock {
  border-radius: 10px;
  display: flex;
  flex: 1 1 50%;
  overflow: hidden;
}

.colorList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
}

.colorList li {
  margin-bottom: 16px;
}

.colorList h2 {
  line-height: 1;
}

.colorVariables {
  display: inline-flex;
  align-items: center;
}

.colorDetails {
  margin-left: 10px;
}

.colorSwatch {
  align-items: flex-end;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  display: flex;
  height: 70px;
  justify-content: flex-start;
  position: relative;
  width: 70px;
}
.colorSwatch small {
  background: #ffffffe8;
  border-radius: 0 0 9px 9px;
  color: #0b0b0b;
  padding: 2px 8px;
  white-space: nowrap;
  flex: 1 1 auto;
  text-align: center;
}

.colorVariables small {
  font-weight: bold;
  margin-right: 5px;
  text-transform: uppercase;
}

.sectionColors li {
  margin-bottom: 8px;
}

.sectionColors li:nth-child(3n-1) {
  margin-top: -20px;
}

.sectionColors li:nth-child(3n) {
  margin-bottom: 16px;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sectionColumns {
  display: flex;
  flex-wrap: wrap;
}
.sectionColumns ul {
  margin-bottom: 16px;
  margin-right: 16px;
  flex: 1 1 auto;
}
