.backContainer {
    margin-top: 10px;
    padding-right: 10px;
    text-align: end;
}

.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
    padding-bottom: 50px;
}

.exportButtonsContainer {
    display: flex;
    padding-top: 20px;
}

.excelButton {
    padding-right: 10px;
}

.xlsxButton {
    padding-left: 10px;
}

.createNewContainer {
    display: flex;
    padding-top: 20px;
}

.createNewContainer span {
    margin-right: auto;
}

.createNewContainer a {
    text-decoration: none;
    background-color: #031d8a;
    color: #ffffff;
    padding: 10px 25px;
}

.searchInputContainer {
    margin-left: auto;
}

.searchInput {
    height: 30px;
    border: 1px solid #031d8a;
    margin-left: 10px;
}

@media (max-width: 1280px) {
    .pageBlockContainer {
        margin-left: 20px;
        margin-right: 20px;
    }

    .createNewContainer button{
        width: fit-content;
    }
}

@media (max-width: 768px) {
    .pageBlockContainer {
        margin-top: 0px;
    }

    .exportButtonsContainer {
        flex-direction: column;
        gap: 10px;
    }

    .searchInputContainer {
        margin-left: 0;
    }

    .xlsxButton {
        padding-left: 0;
    }

    .createNewContainer {
        flex-direction: column;
        gap: 10px;
    }
}