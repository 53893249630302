@value grayMediumSoft from '../../assets/css/variables.css';

.tabButtons {
  border: 1px solid var(--gray-light-hard);
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  margin: 16px auto;
  overflow: hidden;
  width: 100%;
}

.tabButton {
  background: var(--gray-light-soft);
  color: grayMediumSoft;
  flex: 1 1 0;
  line-height: 1.1;
  transition-delay: 200ms;
  transition-property: background-color, color;
  transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.tabButton:hover {
  background-color: grayMediumSoft;
  color: white;
}

.tabButton[disabled] {
  background-color: white;
  color: var(--text-dark);
  cursor: default;
  font-weight: var(--font-weight-semi-bold);
  position: relative;
  z-index: 1;
}

.tabButton:first-of-type[disabled] {
  box-shadow: 1px 0 3px 0 var(--shadow);
}

.tabButton:last-of-type[disabled] {
  box-shadow: -1px 0 3px 0 var(--shadow);
}

.tabButtonInner {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.tabIcon {
  height: auto;
  margin-right: 8px;
  width: 16px;
}
