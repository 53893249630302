/*
  Credit goes to https://dev.to/_elmahdim via https://levelup.gitconnected.com/css-stylish-custom-checkbox-and-radio-input-9b465f193355
*/
.root {
  --color-default: var(--gray-medium-soft);
  --color-active: var(--white);
  --background-active: var(--primary-color);
  --rotate-default: 180deg;
  --rotate-active: 40deg;
  --border-size-checkmark: 2px;
  --border-size-box: 2px;
  --input-size: 20px;
  --gutter: 31px;
}

.root {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  line-height: var(--input-size);
  position: relative;
}

.checkbox {
  clip-path: polygon(0 0);
  position: absolute;
}

.label {
  color: var(--text-dark);
  display: inline-block;
  padding-left: var(--gutter);
}

.label,
.label::before,
.label::after {
  transition: all 0.2s ease-in-out;
}

.label::before {
  border: var(--border-size-box) solid var(--color-default);
  border-radius: 4px;
  content: '';
  display: block;
  height: var(--input-size);
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(0deg) scale(1);
  width: var(--input-size);
}

.label:hover::before {
  box-shadow: 0 0 6px 1px var(--shadow);
}

.label::after {
  border-color: transparent var(--color-active) var(--color-active) transparent;
  border-style: solid;
  border-width: 0 var(--border-size-checkmark) var(--border-size-checkmark) 0;
  content: '';
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(var(--rotate-default)) scale(0);
  width: 5px;
}

.checkbox:checked + .label {
  font-weight: var(--font-weight-bold);
}

.checkbox:checked + .label::after {
  left: 8px;
  transform: rotate(var(--rotate-active)) scale(1);
}

.checkbox:checked + .label::before {
  background-color: var(--background-active);
  border-color: var(--primary-color);
}

.root [data-onchecked='show'],
.checkbox:checked ~ [data-onchecked='hide'] {
  display: none;
}

.root [data-onchecked='hide'],
.checkbox:checked ~ [data-onchecked='show'] {
  display: inline-block;
}

.checkbox:checked + .label.line-across {
  text-decoration: line-through;
}
