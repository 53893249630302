.bar {
  background-color: var(--gray-light-hard);
  border-radius: 4px;
  max-width: 100%;
  position: relative;
}

.bar::before {
  background-color: var(--white);
  border: 1px solid var(--gray-light-hard);
  border-radius: 4px;
  box-shadow: 0 2px 8px var(--shadow);
  color: var(--text-dark);
  content: attr(aria-valuetext);
  display: none;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  left: 50%;
  line-height: 1;
  padding: 4px 16px;
  position: absolute;
  top: 133%;
  transform: translateX(-50%);
  z-index: 9999;
}

.bar:hover::before {
  display: inline-block;
}

.progressIndicator {
  background-color: var(--primary-color);
  border-radius: 4px;
  max-width: 100%;
}

.progressIndicator.is-rtl {
  margin-left: auto;
}

.short {
  height: 4px;
}

.tall {
  height: 8px;
}

.label {
  margin-top: -6px;
  padding-left: 4px;
  font-size: var(--font-size-fine-print);
  color: var(--text-light);
}
