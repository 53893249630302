.navBarComponent {
    z-index: 1;
}

.navBarHeader {
    padding-top: 15px;
    padding-bottom: 15px;
    background: linear-gradient(to right, #0b0a57 0%, #190387 100%);
}

.navBar {
    padding: .5rem 4rem;
    display: flex;
    align-items: center;
}

.navBarImageLink {
    margin-right: auto;
}

.navBarImageMobile {
    display: none;
    height: 45px;
}

.navBarImage {
    height: 45px;
    vertical-align: middle;
    margin-right: auto;
}

.navBarMenu {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.navBarMenuIcon {
    display: inline-block;
    color: #fff;
}

.navBarMenuIcon div {
    width: 20px;
    height: 1px;
    background-color: #fff;
    margin: 6px 0;
}

.navBarMenuText span {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    padding: 5px
}

.navBarLogin {
    display: flex;
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.navBarLoginbutton {
    display: flex;
    flex-direction: row
}

.navBarLoginText span {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    padding: 5px
}

.navBarLogin img {
    position: relative;
    top: 2px;
}

.navBarLogout {
    display: flex;
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.navBarLogoutbutton {
    display: flex;
    flex-direction: row
}

.navBarLogoutText span {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    padding: 5px
}

.navBarLogin img {
    position: relative;
    top: 2px;
}

.navBarDropdownMenu {
    width: 100%;
    position: absolute;
    background-color: #fff;
    height: auto;
    z-index: 20;
}

.navBarDropdownMenuContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 85px;
    margin-bottom: 85px;
}

.menuElementList {
    list-style-type: none;
    padding-right: 50px;
    padding-left: 50px;
}

.menuElementList li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.menuElementTitle {
    color: #001559;
    font-size: 22px;
    font-family: "PT Serif", serif;
    padding: .5rem 0;
    line-height: 30px;
    display: list-item;
}

.menuElementTitleLink {
    text-decoration: none;
    color: #031d8a;
}

.menuElementListLink {
    text-decoration: none;
    font-size: 16px;
    color: #031d8a;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    line-height: 30px;
    font-weight: 350;
}

@media (max-width: 992px) {
    .navBarDropdownMenuContainer {
        justify-content: flex-start;
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .navBarImage {
        display: none;
    }

    .navBarImageMobile {
        height: 45px;
        margin-right: auto;
        display: block;
    }

    .navBar {
        padding: .2rem 2rem;
    }

    .navBarDropdownMenuContainer {
        justify-content: flex-start;
        margin-top: 0;
    }
}