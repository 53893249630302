.pageBlockContainer {
  margin-top: 75px;
  margin-left: 280px;
  margin-right: 280px;
}

.headercontainer {
  padding-top: 65px;
  padding-left: 65px;
}

.subheadercontainer {
  padding-left: 65px;
}

.bodycontainer {
  padding: 65px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
  align-items: center;
  margin: 40px 0;
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  align-items: center;
  margin: 40px 0;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-bottom: 40px;
}

@media (max-width: 1280px) {
  .threeColumn {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

  .threeColumn img {
    max-width: 300px;
  }
}

@media (max-width: 992px) {
  .pageBlockContainer {
    margin-left: 40px;
    margin-right: 40px;
  }

  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

  .twoColumn img {
    max-width: 300px;
  }
}
