.footerContainer {
    display: block;
    align-items: center;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
    background-color: #f0f1f5;
}

.footerPanel {
    margin-bottom: 2.85714rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-right: -15px;
    margin-left: -15px;
}

.footerElementTitle {
    color: #031d8a;
    font-size: 22px;
    font-family: "PT Serif", serif;
    padding: .5rem 0;
    line-height: 30px;
    display: list-item;
}

.footerElementTitleLink {
    text-decoration: none;
    color: #031d8a;
}

.footerElementList {
    list-style-type: none;
}

.footerElementItemLink {
    display: block;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 330;
    padding: .5rem 0;
    font-size: 16px;
    color: #031d8a;
    display: list-item;
    list-style: none;
    margin-left: 0px;
    text-decoration: none;
}

.footerElementDetails {
    display: flex;
    justify-content: center;
}

.footerlogocontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerElementDetailsLink {
    text-decoration: none;
    color: #474747;
}

@media (max-width: 992px) {
    .footerContainer {
        padding: 75px 100px 0 100px;
    }
}

@media (max-width: 768px) {
    .footerPanel {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
    }

    .footerContainer {
        padding: 75px 20px 0 20px;
    }

    .footerElementList {
        padding-left: 10px;
    }

    .footerElementDetails {
        text-align: center;
    }
}