.eventBlockContainer {
    display: flex;
}

.eventBlockImage {
    height: 100%;
}

.eventBlockDetails {
    background-color: #fff;
    padding: 45px;
    height: 100%;
    width: 100%;
}

.eventBlockDetailsDate {
    color: #474747;
    font-size: 17px;
    line-height: 30px;
    display: block;
    padding-bottom: .08rem;
}

.eventBlockDetails h1 {
    margin-bottom: .5rem;
    font-size: 30px;
    line-height: 39px;
    color: #001559;
    font-family: "PT Serif", serif;
}

.eventBlockDetailButton {
    display: inline-block;
    margin-top: 3.57143rem;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #031d8a;
    font-size: 1rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .eventBlockContainer {
        flex-direction: column;
    }

    .eventBlockDetails {
        width: auto;
    }
    
}