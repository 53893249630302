.accordionContent {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.infoIcon,
.infoIconLarge {
  /* TODO: DELETE */
  color: var(--gray-light-hard);
}

.infoIcon {
  /* TODO: DELETE */
  width: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.infoIconLarge {
  /* TODO: DELETE */
  width: 48px;
}

.seperator {
  height: 1px;
  margin: 16px auto;
  width: 100%;
  max-width: 420px;
  background: var(--gray-light-soft);
}

.pl1 {
  padding-left: 1rem;
}

.m4 {
  /* TODO: DELETE */
  margin: 4rem auto;
}

.m1 {
  margin: 1rem auto;
}

.m2 {
  /* TODO: DELETE */
  margin: 2rem auto;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mb2 {
  /* TODO: DELETE */
  margin-bottom: 2rem;
}

.mr1 {
  /* TODO: DELETE */
  margin-right: 1rem;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  text-align: center;
  max-width: 320px;
  margin: 24px auto;
}

.left {
  text-align: left;
  margin: 16px 0;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btnContainer button,
.btnContainer a {
  margin: 4px auto;
  width: 100%;
}

@media (min-width: 768px) {
  .btnContainer {
    align-items: flex-start;
  }
}

.actionButton {
  margin: 0.5rem auto;
}

.fatLink,
.saveTextButton {
  color: var(--primary-color);
  font-weight: bold;
}

.fatLink {
  text-decoration: underline;
}

.saveTextButton:disabled {
  color: var(--gray-medium-soft);
  background: none;
}

.smallPill {
  color: white;
  padding: 0 16px;
  font-size: 12px;
  height: 32px;
  color: var(--gray-medium-soft);
  border-color: var(--gray-medium-soft);
}

.littleIcon {
  width: auto;
  height: 19px;
}

.xsIcon {
  width: auto;
  height: 14px;
}

.iconListItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconListItem .fatLink {
  margin-left: 8px;
}

.warningText {
  color: var(--danger-red);
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 5px;
  min-width: 320px;
  margin-bottom: 32px;
}

.table thead tr th {
  padding-bottom: 6px;
  border-bottom: 1px solid var(--gray-light-hard);
  border-collapse: separate;
  border-spacing: 5px 5px;
}

.table thead th {
  font-size: 11px;
  font-weight: 100;
  color: var(--gray-medium-soft);
  padding: 4px;
}

.table tbody tr td {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
}

.tableIcon {
  width: 28px;
  height: 28px;
}

.mutedText {
  color: var(--gray-medium-soft);
  font-weight: 100 !important;
}

.hiddenInput {
  composes: visually-hidden from '../../assets/css/helpers.css';
}

.fullScreenCropper {
  background-color: white;
  padding: 32px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
}

.infoText {
  color: var(--dream-blue);
}

.highlightFigure strong {
  color: var(--medium-electric-blue) !important;
}

.highlightFigure figcaption {
  text-align: right !important;
}

.warningFigure strong {
  color: var(--danger-red) !important;
}

.figSmall strong {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.figMed strong {
  font-size: 16px !important;
  font-weight: 100 !important;
}

.figLarge strong {
  font-size: 18px !important;
}

.circleButtonLink {
  width: 56px;
  height: 56px;
  padding: 0;
}

.linkIcon {
  color: #fff;
  max-height: 58px;
}

.iconText {
  font-size: var(--font-size-fine-print);
  text-align: center;
  padding-right: 10px;
  margin-top: 6px;
  margin-bottom: 0px;
  color: var(--gray-medium-soft);
}