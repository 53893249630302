.pageBlockContainer {
  margin-top: 75px;
  margin-left: 280px;
  margin-right: 280px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .pageBlockContainer {
    margin-left: 40px;
    margin-right: 40px;
  }
}
