.formContainer {
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
}

.afi_SignUp {
    background: var(--white);
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
}

.afi_SignUp_wrapper {
    background-color: var(--page-bg);
    border: 1px solid;
    margin-top: 12px;
    max-width: 400px;
    overflow: hidden;
    padding: 32px 20px 20px;
    text-align: center;
    width: 68%;
}

.afi_SignUp_field {
    margin-bottom: 24px;
    max-width: 260px;
    width: 100%;
}

.afi_SignUp_btn {
    display: block;
    margin-bottom: 12px;
    max-width: 260px;
    width: 100%;
}

.afi_SignUp_forgotPassword {
    display: block;
    margin-bottom: 32px;
    text-decoration: none;
    text-align: center;
}

.afi_SignUp_forgotPassword:hover {
    text-decoration: underline;
}

.afi_SignUp_error {
    color: var(--brick-red);
    font-size: 16px;
    padding: 0;
    margin-bottom: 8px;
    margin-top: -16px;
}

.afi_SignUp_no_account {
    display: block;
    margin-bottom: 8px;
}

.messageBarSuccess {
    margin-bottom: 12px;
}