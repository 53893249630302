.scientificProgrammeContainer {
    background-color: #f0f1f5;
    padding-top: 30px;
}

.scientificProgrammeContainer h1 {
    font-size: 42px;
    font-family: "PT Serif", serif;
    color: #001559;
    padding-left: 15px;
}

.scientificProgrammeContainer hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-top: 15px;
    margin-bottom: 15px;
}

.scientificProgrammeContainer h3 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 20px;
    color: #001559;
    font-weight: 500;
    padding-left: 15px;
}

.mainSubjectsContainer {
    display: flex;
    flex-direction: row;
}

.mainSubjectOneContainer {
    padding-left: 15px;
    padding-right: 15px;
    flex-basis: 50%;
}

.mainSubjectTwoContainer {
    padding-left: 15px;
    padding-right: 15px;
    flex-basis: 50%;
}

.mainSubjectOne {
    background-color: #001559;
    padding: 20px;
}

.mainSubjectOne p {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.mainSubjectOne hr {
    background-color: #f0f1f5;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    opacity: .3;
}

.mainSubjectOne h4 {
    color: #bbb;
    font-family: "PT Serif", serif;
    margin-bottom: 10px;
}

.mainSubjectOneReportersContainer p {
    font-size: 15px;
}

.mainSubjectTwo {
    background-color: #001559;
    padding: 20px;
}

.mainSubjectTwo p {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.mainSubjectTwo hr {
    background-color: #f0f1f5;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    opacity: .3;
}

.mainSubjectTwo h4 {
    color: #bbb;
    font-family: "PT Serif", serif;
    margin-bottom: 10px;
}

.mainSubjectTwoReportersContainer p {
    font-size: 15px;
}

@media (max-width: 768px) {
    .mainSubjectsContainer {
        flex-direction: column;
        gap: 20px;
    }
}