.pageBlockContainer {
  margin-top: 75px;
  margin-left: 280px;
  margin-right: 280px;
  margin-bottom: 75px;
}

.blockElementPanel {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
}

.aboutContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5.71429rem;
  align-items: center;
}

.calendarContainer {
  background-color: #001559;
  display: flex;
  flex-direction: row;
  padding-top: 75px;
  padding-left: 280px;
  padding-right: 280px;
  padding-bottom: 80px;
}

.calendarListContainer {
  flex-basis: 70%;
}

.calendarBlockContainer {
  flex-basis: 30%;
}

.aboutContainerBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 32px;
}

.yearlyLogos {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5.71429rem;
  margin-bottom: 5.71429rem;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.imageContainer img {
  object-fit: contain;
}

.yearlyLogos img {
  object-fit: contain;
}

.yearlyLogos img:last-of-type {
  max-width: 200px;
}

@media (max-width: 992px) {
  .pageBlockContainer {
    margin: 75px 20px;
  }

  .calendarContainer {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (max-width: 768px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
  }

  .blockElementPanel {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }

  .calendarContainer {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
  }

  .yearlyLogos {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 5.71429rem;
    margin-bottom: 5.71429rem;
  }

  .aboutContainerBlock {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 32px;
  }
}
