.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
}

.headercontainer {
    padding-top: 65px;
    padding-left: 65px;
}

.subheadercontainer {
    padding-left: 65px;
}

.bodycontainer {
    padding: 65px;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
}