.tableContainer {
    width: 100%;
    display: table;
    border-collapse: collapse;
    float: left;
}

.tableContainer tr {
    border-bottom: 1px solid #e3e3e3;
}

.tableContainer tr:first-child {
    border-top: 1px solid #e3e3e3;
}

.tableContainer td:first-child {
    height: 30px;
    display: table-cell;
    vertical-align: top;
    padding: 15px 25px;
    border: none;
}

.actionButtons {
    display: flex;
    justify-content: center;
}

.actionButtons a {
    margin-left: 10px;
    text-decoration: none;
    /* background-color: #031d8a;
    padding: 5px 10px;
    color: #ffffff; */
}

.isMobile {
    display: none;
}

@media (max-width: 768px) {
    .tableContainer {
        margin-top: 0px;
    }

    .isDesktop {
        display: none;
    }

    .isMobile {
        display: flex;
    }

    .list {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .listItem {
        margin-bottom: 10px;
        list-style: none;
        padding: 15px 0 0 0;
        border-top: 1px solid #e3e3e3;
    }

    .dataRow {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;
    }

    .actionButtons {
        margin-top: 10px;
    }
}