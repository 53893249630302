.imageCarouselContainer {
    position: relative;
}

.imageDetails {
    position: absolute;
    bottom: 10%;
    left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.imageDetailDates {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

.imageDetailLocation {
    color: #fff;
    font-weight: 400;
    font-size: 40px;
    font-family: "PT Serif", serif;
    text-align: left;
}

.readMoreLink {
    text-decoration: none;
    align-items: center;
}

.readMoreButton {
    display: flex;
    margin-top: 0;
    font-size: 20px;
}

.readMoreText {
    background-color: #fff;
    color: #031d8a;
    font-weight: 500;
    font-size: 15px;
    display: block;
    text-decoration: none;
    padding: 12px;
}

.readMoreButtonIcon {
    display: block;
    background-color: #ebedf6;
    padding: 12px;
    color: #031d8a;
    font-weight: 500;
    line-height: 20px;
}

.readMoreButtonIcon svg {
    width: 27px;
    height: 10px;
}

@media (max-width: 768px) {
    .imageDetailLocation {
        font-size: 15px;
    }

    .readMoreButtonIcon, .readMoreText{
        padding: 8px;
    }

    .imageDetails {
        gap: 5px;
    }
}