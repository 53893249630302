.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
    padding-bottom: 50px;
}

@media (max-width: 1280px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (max-width: 768px) {
    .pageBlockContainer {
        margin-left: 20px;
        margin-right: 20px;
    }
}