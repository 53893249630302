.backContainer {
    margin-top: 30px;
    padding-right: 30px;
    text-align: end;
}

.pageBlockContainer {
    margin-top: 25px;
    margin-left: 280px;
    margin-right: 280px;
    padding-bottom: 50px;
}

.memberNameContainer {
    padding-bottom: 20px;
}

.detailsContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    line-height: 1.4;
    font-size: 30px;
    font-weight: 500;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: 120px 120px;
    column-gap: 30px;
}

.buttonContainer {
    width: 120px;
    background-color: #031d8a;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (max-width: 768px) {
    
}