.button {
    color: var(--white);
    border: 0;
}

.primary {
    background-color: var(--primary-color);
}

.primary:hover,
.primary:active {
    background-color: var(--primary-color-hover);
}

.maintain {
    background-color: var(--maintain-color);
}

.maintain:hover,
.maintain:active {
    background-color: var(--maintain-color-hover);
}

.plan {
    background-color: var(--plan-color);
}

.plan:hover,
.plan:active {
    background-color: var(--plan-color-hover);
}

.manage {
    background-color: var(--manage-color);
}

.manage:hover,
.manage:active {
    background-color: var(--manage-color-hover);
}

.motivate {
    background-color: var(--motivate-color);
}

.motivate:hover,
.motivate:active {
    background-color: var(--motivate-color-hover);
}

.expenditure {
    background-color: var(--expenditure-color);
}

.expenditure:hover,
.expenditure:active {
    background-color: var(--expenditure-color-hover);
}

.danger {
    background-color: var(--afirefi-red);
}

.danger:hover,
.danger:active {
    opacity: 0.8;
}

.buttonIcon {
    composes: rightIcon from './BaseButton.module.css';
}