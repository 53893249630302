.personalDetailsContainer {
    padding-bottom: 20px;
}

.personalDetailsContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    line-height: 1.4;
    font-size: 30px;
    font-weight: 500;
}

.tableContainer {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.tableContainer tbody tr {
    height: 40px;
}

.tableContainer tbody tr .tableContainerName {
    width: 40%;
}

.addressTypeInputContainer {
    width: 51%;
    height: 30px;
    border: 1px solid #031d8a;
}

.searchInputContainer {
    margin-left: auto;
}

.searchInput {
    width: 50%;
    height: 30px;
    border: 1px solid #031d8a;
}

@media (max-width: 768px) {
    .searchInput {
        width: auto;
    }

    .addressTypeInputContainer {
        width: auto;
    }
}