.blockLink {
    text-decoration: none;
}

.blockcontainer {
    display: flex;
    flex-direction: column;
    height: 230px;
    width: 230px;
    padding-left: 20px;
    padding-top: 20px;
    position: relative;
    background-color: #fff;
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    line-height: 1.4;
    font-size: 1.5rem;
}

.blockcontainer:hover {
    background-color: #001559;
    color: #fff;
}

.blocktitle h1 {
    text-decoration: none;
}

.btnprimaryicon {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 24px;
    font-size: 17px;
    line-height: 10px;
    padding-right: 15px;
}