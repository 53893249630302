.pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
    display: flex;
    flex-direction: row;
}

.containerColumnOne {
    padding-right: 15px;
    padding-left: 15px;
}

.containerColumnTwo {
    padding-right: 15px;
    padding-left: 15px;
}

.containerColumnTwo img {
    width: 100%;
    margin-bottom: 25px;
}

.scientificBlockContainer {
    margin-top: 75px;
    padding-bottom: 75px;
    padding-left: 280px;
    padding-right: 280px;
    background-color: #f0f1f5;
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
    
    .scientificBlockContainer {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px) {
    .pageBlockContainer {
        flex-direction: column;
    }
}