/* ////////////////////////////////Common Styles///////////////////////////// */

.eventSpeakerDetailContainer {
    display: flex;
    flex-direction: row;
}

.eventSpeakerDetailContainer img {
    height: auto;
}

.eventSpeakerInfoContainer {
    padding-left: 10px;
    height: 100%;
}


/* ////////////////////////////////Grey Style///////////////////////////// */

.eventSpeakerContainerGrey {
    background-color: #f0f1f5;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 30px;
}

.eventSpeakerContainerGrey h3 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerGrey h4 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerGrey h5 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerGrey hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.eventSpeakerContainerGrey p {
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
}


/* ////////////////////////////////Light Style///////////////////////////// */

.eventSpeakerContainerLight {
    background-color: #ffffff;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 30px;
}

.eventSpeakerContainerLight h3 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerLight h4 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerLight h5 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerLight hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.eventSpeakerContainerLight p {
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
}


/* ////////////////////////////////Dark Style///////////////////////////// */

.eventSpeakerContainerDark {
    background-color: #001559;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 30px;
}

.eventSpeakerContainerDark h3 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #f0f1f5;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerDark h4 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #f0f1f5;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerDark h5 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #f0f1f5;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}

.eventSpeakerContainerDark hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(216, 212, 212, 0.1);
}

.eventSpeakerContainerDark p {
    color: #f0f1f5;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
}