.registerationContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f0f1f5;
}

.registerationButtonContainer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.registerButton {
    text-decoration: none;
    color: #001559;
}


/* ////////////////////////////////Grey Style///////////////////////////// */

.eventDetailsContainerGrey {
    padding: 30px;
    background-color: #f0f1f5;
    width: 350px;
    margin-bottom: 30px;
}

.eventDetailsContainerGrey h2 {
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerGrey table td {
    display: table-cell;
    padding: 5px 0;
}

.eventDetailsContainerGrey span {
    display: inline-block;
    width: 100px;
    color: #001559;
    font-weight: bold;
}

.eventDetailsContainerGrey hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.eventDetailsContainerGrey button {
    background-color: #001559;
    color: #f0f1f5;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* ////////////////////////////////Light Style///////////////////////////// */

.eventDetailsContainerLight {
    padding: 30px;
    background-color: #ffffff;
    width: 350px;
    margin-bottom: 30px;
}

.eventDetailsContainerLight h2 {
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerLight table td {
    display: table-cell;
    padding: 5px 0;
}

.eventDetailsContainerLight span {
    display: inline-block;
    width: 100px;
    color: #001559;
    font-weight: bold;
}

.eventDetailsContainerLight hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.eventDetailsContainerLight button {
    background-color: #001559;
    color: #f0f1f5;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* ////////////////////////////////Dark Style///////////////////////////// */

.eventDetailsContainerDark {
    padding: 30px;
    background-color: #001559;
    width: 350px;
    margin-bottom: 30px;
}

.eventDetailsContainerDark h2 {
    color: #f0f1f5;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerDark table td {
    display: table-cell;
    padding: 5px 0;
    color: #f0f1f5;
}

.eventDetailsContainerDark span {
    display: inline-block;
    width: 100px;
    color: #f0f1f5;
    font-weight: bold;
}

.eventDetailsContainerDark hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(216, 212, 212, 0.1);
}

.eventDetailsContainerDark button {
    background-color: #f0f1f5;
    color: #001559;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 767px) {
    .eventDetailsContainerGrey, .eventDetailsContainerDark, .eventDetailsContainerDark {
        width: auto;
    }
}