.companySelectorButton {
    border-top: 1px solid #001559;
    padding-top: 10px;
    padding-bottom: 10px;
}

.companySelectorButton:hover {
    border-top: 1px solid #ffffff;
    background-color: #001559;
    color: #ffffff;
}

.companySelectorButtonText {}