@value variables: '../../assets/css/variables.css';
.root {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-bottom: 32px;
    position: relative;
}

.label {
    composes: large-text from '../../assets/css/typography.css';
    text-align: center;
}

.labelIsHidden {
    composes: visually-hidden from '../../assets/css/helpers.css';
}

.input {
    border: 1px solid;
    background-color: var(--form-inputs-bg);
    box-shadow: none;
    color: var(--form-inputs-text-color);
    font-weight: var(--font-weight-regular);
    letter-spacing: -1px;
    max-width: 400px;
    padding: 6px;
    text-align: center;
    transition: border var(--transition-duration) ease-in-out;
    width: 100%;
}

.input:hover,
.input:focus {
    border-color: var(--gray-medium-soft);
}

.input.inputError {
    border-color: var(--error-red);
}

.input::placeholder {
    color: var(--form-inputs-placeholder-color);
    font-style: normal;
}

.errorMessage {
    composes: smallText boldText from '../../assets/css/typography.css';
    background-color: var(--white);
    color: var(--error-red);
    line-height: 1.2;
    padding: 6px;
    text-align: center;
    width: 100%;
    max-width: 90%;
}

.errorMessageInner {
    position: relative;
}

.errorIcon {
    height: 15px;
    left: -28px;
    position: absolute;
    top: 1px;
    width: 19px;
}