.container {
    padding: 15px;
    border: 2px solid #fff;
    text-rendering: optimizelegibility;
}

.blockcontainer {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
}

.blockcontainer h3 {
    font-family: "PT Serif", serif;
    font-size: 20px;
    color: #031d8a;
    line-height: 35px;
    margin: 0;
}

.blockcontainer h2 {
    font-family: "PT Serif", serif;
    margin-top: 0;
    color: #001559;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
}

.blockcontainer p {
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 1rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.btnprimary {
    display: flex;
    align-items: center;
}

.btnprimarytext {
    background-color: transparent;
    color: #031d8a;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    border-width: 0;
    color: #031d8a;
    text-decoration: none;
}

.btnprimaryicon {
    color: #031d8a;
    font-size: 17px;
    line-height: 30px;
}

.btnprimaryicon svg {
    width: 27px;
    height: 10px;
}

.btnprimary .btnprimaryicon {
    padding-left: 20px;
    display: inline-block;
    background-color: transparent;
}