.pageheaderContainerLight span {
    background-color: #ffffff;
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    /* line-height: 30px; */
}

.pageheaderContainerDark span {
    background-color: #001559;
    color: #ffffff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    /* line-height: 30px; */
}