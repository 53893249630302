.personalDetailsContainer {
    padding-bottom: 20px;
}

.personalDetailsContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    line-height: 1.4;
    font-size: 30px;
    font-weight: 500;
}

.tableContainer {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.tableContainer tbody tr {
    height: 40px;
}

.tableContainer tbody tr .tableContainerName {
    width: 40%;
}

.tableContainer tbody tr .tableContainerName {
    width: 40%;
}

.headerContainer {
    display: flex;
    flex-direction: row;
}

.buttonContainer {
    background-color: #031d8a;
    color: #ffffff;
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 1.5;
}