.personalDetailsContainer {
    padding-bottom: 20px;
}

.personalDetailsContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    line-height: 1.4;
    font-size: 30px;
    font-weight: 500;
}

.tableContainer {
    width: 100%;
    display: table;
    border-collapse: collapse;
}

.tableContainer tbody tr {
    height: 40px;
}

.tableContainer tbody tr .tableContainerName {
    width: 40%;
}

.addressTypeInputContainer {
    width: 51%;
    height: 30px;
    border: 1px solid #031d8a;
}

.searchInputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.searchInput {
    width: 50%;
    height: 30px;
    border: 1px solid #031d8a;
}

.buttonContainer {
    margin-top: 20px;
    background-color: #031d8a;
    color: #ffffff;
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 1.5;
}

.selectCompany {
    background-color: #031d8a;
    color: #ffffff;
    margin-left: 20px;
    padding: 8px 30px;
}

@media (max-width: 768px) {
    .addressTypeInputContainer {
        width: 100%;
    }
}