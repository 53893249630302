.pageBlockContainerLight {
    background-color: #ffffff;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
    padding-bottom: 75px;
}

.pageBlockContainerDark {
    background-color: #001559;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
    padding-bottom: 75px;
}

.eventTitleContainerLight {
    background-color: #ffffff;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
}

.eventTitleContainerDark {
    background-color: #001559;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
}

.eventDetailsContainer {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1280px) {
    .pageBlockContainerLight,
    .pageBlockContainerDark,
    .eventTitleContainerLight,
    .eventTitleContainerDark {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width:768px) {
    .pageBlockContainerDark,
    .pageBlockContainerLight {
        flex-direction: column;
    }

    .eventDetailsContainer {
        flex-direction: column;
    }
}