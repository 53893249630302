.pageBlockContainer {
    background-color: #001559;
    padding-top: 75px;
    padding-left: 280px;
    padding-right: 280px;
    padding-bottom: 75px;
}

.calendarHeaderContainer {
    display: flex;
    flex-direction: row;
    background-color: #001559;
}

.calendarHeaderContainer h1 {
    font-size: 42px;
    line-height: 50px;
    font-family: "PT Serif", serif;
    color: #fff;
    font-weight: 500;
}

.calendarHeaderButtonsContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.calendarHeaderButton {
    padding: 10px;
    color: #ffffff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
}

@media (max-width: 1280px) {
    .pageBlockContainer {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px) {
    .calendarHeaderContainer {
        flex-direction: column;
    }

    .calendarHeaderButtonsContainer {
        flex-direction: column;
        margin-left: 0;
    }
}