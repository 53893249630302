.root {
  composes: border-box from '../../assets/css/helpers.css';
  margin: 0 auto;
  width: 100%;
}

.header {
  --close-button-color: var(--gray-medium-hard);
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 8px;
  position: relative;
  padding: 24px;
}

.body {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: var(--max-width);
  padding: 24px;
}

.icon {
  height: 48px;
  width: 48px;
}

.headerCurveTipIcon {
  color: white;
}

.close {
  display: block;
  height: 20px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 20px;
  color: inherit !important;
}

.title {
  color: inherit !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  margin-top: 8px !important;
}

.close::before,
.close::after {
  box-shadow: 0 0 0 0 var(--shadow);
  background-color: var(--close-button-color);
  border-radius: 3px;
  content: '';
  display: block;
  height: 3px;
  left: -2px;
  position: absolute;
  transition-duration: var(--transition-duration);
  transition-property: box-shadow;
  transition-timing-function: var(--transition-timing);
  width: 25px;
}

.close::before {
  top: 9px;
  transform: rotate(45deg);
}
.close::after {
  bottom: 8px;
  transform: rotate(135deg);
}

.close:hover::before,
.close:hover::after,
.close:focus::before,
.close:focus::after {
  box-shadow: 0 0 10px 2px var(--shadow);
}

.close:hover:active::before,
.close:hover:active::after {
  background-color: var(--gray-light-hard);
  box-shadow: none;
}

.type--danger {
  color: var(--danger-color);
}

.type--plan {
  color: var(--plan-color);
}

.type--maintain {
  color: var(--maintain-color);
}

.type--motivate {
  color: var(--motivate-color);
}

.type--manage {
  color: var(--manage-color);
}

.type--expenditure {
  color: var(--expenditure-color);
}

.headerCurve {
  --close-button-color: var(--white);
  overflow: hidden;
  padding: 24px 24px 32px 24px;
  position: relative;
  text-align: center;
  z-index: 2;
}

.headerCurve::before {
  background-color: currentColor;
  border-radius: 150vw;
  bottom: 0;
  content: '';
  display: block;
  height: 300vw;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 300vw;
  z-index: -1;
}

.headerCurve .icon {
  color: var(--white);
}

.headerCurve .title {
  color: var(--white) !important;
}

@media (min-width: 768px) {
  .close {
    right: 25%;
  }
}
