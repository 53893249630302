.formContainer {
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
}

.afi_wrapper {
    background-color: var(--page-bg);
    border: 1px solid;
    margin-top: 12px;
    max-width: 400px;
    overflow: hidden;
    padding: 40px 20px 20px;
    text-align: center;
    width: 68%;
}

.afi_form {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.afi_form p:first_child {
    font-size: var(--font-size-small);
    margin-bottom: 40px;
}

.afi_field {
    margin-bottom: 36px;
    max-width: 260px;
    width: 100%;
}

.afi_btn {
    margin-bottom: 12px;
}

.afi_password_remembered {
    display: block;
    margin-bottom: 8px;
}

.backToHomepage_btn {
    margin-top: 24px;
    margin-bottom: 12px;
}

.messageBarSuccess {
    margin-bottom: 12px;
}

.messageBarError {
    margin-top: -12px;
    margin-bottom: 24px;
}