.root {
  line-height: 1.1;
  margin: 0;
}

.root strong {
  font-size: var(--font-size-impact);
  font-weight: var(--font-weight-bold);
  letter-spacing: 1px;
}

.root figcaption {
  color: var(--gray-medium-soft);
  font-size: var(--font-size-fine-print);
  letter-spacing: 0.5px;
  margin-top: 4px;
}

.plan strong {
  color: var(--plan-color);
}

.maintain strong {
  color: var(--maintain-color);
}

.manage strong {
  color: var(--manage-color);
}

.motivate strong {
  color: var(--motivate-color);
}

.expenditure strong {
  color: var(--expenditure-color);
}

.light strong {
  color: var(--white);
}

.light figcaption {
  color: var(--white);
}

.dark strong {
  color: var(--text-dark);
}

.dark figcaption {
  color: var(--text-dark);
}

.figureRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.figureRow figcaption {
  font-size: 14px;
}

.figureRow strong {
  font-weight: 700;
  font-size: 18px;
}

.valueContainerWithIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoButton,
.infoIcon {
  color: var(--gray-light-hard);
  height: 16px;
  width: 16px;
}
