.calendarEvent {
    /* width: 100%; */
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.calendarEvent {}

.calendarEventImage img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
}

.calendarEvent .calendarEventBody {
    padding: 45px;
    background-color: #fff;
    width: 100;
}

.calendarEvent .calendarEventBodySubtitle {
    color: #474747;
    font-size: 17px;
    line-height: 30px;
    display: block;
}

.calendarEvent h2 {
    font-family: "PT Serif", serif;
    color: #001559 !important;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
}

.btnprimary {
    display: flex;
    align-items: center;
}

.btnprimarytext {
    background-color: transparent;
    color: #031d8a;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    border-width: 0;
    color: #031d8a;
    text-decoration: none;
}

.btnprimaryicon {
    color: #031d8a;
    font-size: 17px;
    line-height: 30px;
}

.btnprimaryicon svg {
    width: 27px;
    height: 10px;
}

.btnprimary .btnprimaryicon {
    padding-left: 20px;
    display: inline-block;
    background-color: transparent;
}