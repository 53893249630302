.root {
  margin-right: 2em;
  text-align: justify;
}

.root h1 {
  margin-bottom: 0.5em;
}

.root p {
  margin-bottom: 1.5em;
}
