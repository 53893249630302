.backContainer {
    margin-top: 10px;
    padding-right: 10px;
    text-align: end;
}

/* .pageBlockContainer {
    margin-top: 75px;
    margin-left: 280px;
    margin-right: 280px;
    padding-bottom: 50px;
} */

.pageBlockContainer {
    display: flex;
    justify-content: center;
    margin-top: 175px;
    margin-left: 280px;
    margin-right: 280px;
    padding-bottom: 50px;
}

.createNewUserStatement h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    font-weight: 500;
    line-height: 1.4;
    font-size: 25px;
}

.newUserForm {
    display: flex;
    justify-content: center;
    border: 1px solid #031d8a;
    padding: 0 30px;
}

.formTitleContainer {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitleContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    line-height: 1.4;
    font-size: 25px;
}

.searchInputContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-top: 40px;
}

.searchInput {
    width: 250px;
    height: 40px;
    border: 1px solid #031d8a;
}

.buttonContainer {
    background-color: #031d8a;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.buttonsContainer {
    padding-top: 30px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
}

@media (max-width: 1080px) {
    .pageBlockContainer {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 75px;
    }
}