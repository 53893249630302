.main {
  background-color: var(--page-bg);
  border-radius: 16px 16px 0 0;
  margin: 12px auto 0;
  max-width: 400px;
  min-height: 270px;
  overflow: hidden;
  padding: 32px 20px 20px;
  text-align: center;
  width: 68%;
}

.subTitle {
  margin-bottom: 32px;
}

.message {
  margin-bottom: 72px;
}

.resetPasswordForm {
  margin: 0 auto;
  display: grid;
  justify-content: center;
}
