.videoContainer {
    /* height: 180px; */
    width: 50%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}

.materialsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.seminarFilesContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.seminarFileContainer {
    width: 100%;
    border-bottom: 1px solid #f0f1f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
}

.imageContainer {
    vertical-align: middle;
    align-items: center;
    width: 20px;
    height: 20px;
}


/* ////////////////////////////////Grey Style///////////////////////////// */

.eventDetailsContainerGrey {
    padding: 30px;
    background-color: #f0f1f5;
    width: 100%;
    margin-bottom: 30px;
}

.eventDetailsContainerGrey h2 {
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerGrey hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}


/* ////////////////////////////////Light Style///////////////////////////// */

.eventDetailsContainerLight {
    padding: 30px;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 30px;
}

.eventDetailsContainerLight h2 {
    color: #001559;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerLight hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}


/* ////////////////////////////////Dark Style///////////////////////////// */

.eventDetailsContainerDark {
    padding: 30px;
    background-color: #001559;
    width: 100%;
    margin-bottom: 30px;
}

.eventDetailsContainerDark h2 {
    color: #f0f1f5;
    font-family: "PT Serif", serif;
    font-weight: 500;
    font-size: 30px;
}

.eventDetailsContainerDark p {
    color: #f0f1f5;
    padding-top: 5px;
    padding-left: 20px;
}

.eventDetailsContainerDark hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(216, 212, 212, 0.1);
}

@media (max-width: 992px) {
    .materialsContainer {
        flex-direction: column;
    }

    .videoContainer {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .videoContainer {
        width: auto;
    }

    .eventDetailsContainerDark, .eventDetailsContainerGrey, .eventDetailsContainerWhite {
        width: auto;
    }
}