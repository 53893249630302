@value variables: '../../assets/css/variables.css';
@value fontSizeSmallText from variables;

.root {
  composes: button from '../buttons/BaseButton.module.css';
  align-items: center;
  display: inline-flex;
  position: relative;

  background-color: var(--gray-light-soft);
  border-color: var(--gray-light-soft);
  color: var(--text-dark);
  font-size: fontSizeSmallText;

  justify-content: flex-start;
}

.root:hover {
  background-color: var(--gray-medium-hard);
  border-color: var(--gray-medium-hard);
  color: var(--gray-light-soft);
}

.root:active {
  background-color: var(--gray-medium-soft);
  border-color: var(--gray-medium-soft);
  color: var(--gray-light-soft);
}

.primary {
  background-color: var(--dream-blue);
  border-color: var(--dream-blue);
  color: white;
}

.secondary {
  background-color: transparent;
  border: 1px solid var(--dream-blue);
  color: var(--dream-blue);
}

.dark {
  background-color: var(--gray-dark-hard);
  color: white;
  border: none;
}

.leftButtonIcon {
  left: 16px;
  height: 34px;
  width: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rightButtonIcon {
  composes: rightIcon from '../buttons/BaseButton.module.css';
}
