.errorText {
  align-items: center;
  display: flex;
  color: var(--error-red);
  margin-bottom: 10px;
  padding: 5px 0;
}

.errorIcon {
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.boxBorder {
  border: 1px solid var(--error-red);
  border-radius: 3px;
  padding: 8px;
  margin: 16px 8px;
}
