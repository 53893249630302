/* @import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap'); */

.titleText {
  font-size: var(--font-size-sub-title);
}

.smallText {
  font-size: var(--font-size-small);
}

.boldText {
  font-weight: var(--font-weight-bold);
}

.page-title {
  color: var(--text-dark);
  composes: titleText;
  composes: boldText;
  line-height: 1.25;
}

.sub-title {
  color: var(--text-dark);
  font-size: var(--font-size-sub-title);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.375;
}

/* TODO: review this */
.large-text {
  color: var(--text-light);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  line-height: 1.2;
}
/* TODO: review this */
.small-text {
  color: var(--text-light);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: 1.3;
}

.fine-print {
  color: var(--text-light);
  font-size: var(--font-size-fine-print);
  font-weight: var(--font-weight-regular);
  line-height: 1.3;
}

.apexcharts-datalabel-label {
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-light);
}

.apexcharts-datalabel-value {
  font-size: var(--font-size-impact);
  font-weight: var(--font-weight-regular);
  letter-spacing: 1px;
}
