:root {
    /**************** Main colors */
    /* For hover state HSL colors reduce the 'L' percentage by 10 */
    --danger-red: var(--brick-red);
    --danger-red-hover: var(--brick-red);
    --dream-blue: #031d8a;
    --dream-blue-hover: hsl(213, 100%, 34%);
    --river-blue: hsl(201, 99%, 31%);
    --river-blue-hover: hsl(201, 99%, 21%);
    --bright-teal: hsl(184, 98%, 44%);
    --bright-teal-hover: hsl(184, 98%, 34%);
    --mustard-yellow: hsl(42, 95%, 53%);
    --mustard-yellow-hover: hsl(42, 95%, 43%);
    --night-blue: hsl(230, 47%, 25%);
    --night-blue-hover: hsl(230, 47%, 15%);
    --primary-color: var(--dream-blue);
    --primary-color-hover: var(--dream-blue-hover);
    /**************** Section color variables */
    --danger-color: var(--danger-red);
    --danger-color-hover: var(--danger-red-hover);
    --plan-color: var(--dream-blue);
    --plan-color-hover: var(--dream-blue-hover);
    --maintain-color: var(--river-blue);
    --maintain-color-hover: var(--river-blue-hover);
    --manage-color: var(--bright-teal);
    --manage-color-hover: var(--bright-teal-hover);
    --motivate-color: var(--mustard-yellow);
    --motivate-color-hover: var(--mustard-yellow-hover);
    --expenditure-color: var(--night-blue);
    --expenditure-color-hover: var(--night-blue-hover);
    /**************** Grays */
    --gray-light-soft: #f4f3f2;
    --gray-light-hard: #cdcdd3;
    --gray-medium-soft: #8f8e94;
    --gray-medium-hard: #818181;
    --gray-dark-soft: #697a86;
    --gray-dark-hard: #4e4c59;
    /**
   * minimum gray to meet 4.5:1 constrast ratio on white background,
   * at 16px font size.
   */
    --gray-text-white-bg: #767676;
    /**************** Text */
    --text-dark: var(--gray-dark-hard);
    --text-light: var(--gray-medium-soft);
    /**************** General colors */
    --blue: #0057c1;
    --afirefi-red: #cc2a3c;
    --brick-red: #c92d3f;
    --teal: #20a4af;
    --peach: #f56c6c;
    --white: #fff;
    --black: #000;
    --page-bg: var(--white);
    --medium-electric-blue: #00479d;
    --dark-pastel-blue: #7fa3ce;
    --jordy-blue: #80b2ee;
    --jordy-blue-transparent: rgba(128, 178, 238, 0.15);
    --jordy-blue-less-transparent: rgba(128, 178, 238, 0.4);
    /**************** Form colors */
    --form-inputs-bg: var(---white);
    --form-inputs-hover-bg: var(--gray-light-soft);
    --form-inputs-text-color: var(--gray-dark-hard);
    --form-inputs-placeholder-color: var(--gray-light-hard);
    --error-red: #eb0909;
    /* ***************************** Additional Colors *******************************/
    --shadow: #00000029;
    --amethyst: #995dd1;
    --sea-buckthorn: #fda527;
    /*************** Asset type colors */
    --property-color: var(--amethyst);
    --jewelry-color: var(--sea-buckthorn);
    --art-color: #fc5a69;
    --cash-color: var(--dream-blue);
    --retirement-color: var(--bright-teal);
    --investment-color: #da4ab5;
    /*************** Cash Account purpose colors */
    --dwelling-color: var(--river-blue);
    --household-color: var(--amethyst);
    --business-color: var(--teal);
    --personal-color: var(--sea-buckthorn);
    /*************** Life Element colors */
    --play-color: var(--teal);
    --create-color: var(--night-blue);
    --explore-color: #dc70c1;
    --ritual-color: #a3ca61;
    --essential-color: #fd6265;
    --lead-color: var(--sea-buckthorn);
    --sleep-color: var(--river-blue);
    --other-color: #697a86;
    /*************** Fonts */
    --default-font-family: 'Nunito Sans', sans-serif;
    --font-family-cursive: 'Shadows Into Light Two', cursive;
    --font-size-title: 28px;
    --font-size-impact: 24px;
    --font-size-sub-title: 20px;
    --font-size-large: 18px;
    --font-size-default: 16px;
    --font-size-small: 14px;
    --font-size-fine-print: 12px;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    /*************** Misc. */
    --base-spacing: 8px;
    --default-border-radius: 12px;
    --border-radius-large: 16px;
    --border-radius-small: 8px;
    --transition-duration: 300ms;
    --transition-timing: cubic-bezier(0.77, 0, 0.18, 1);
    --max-width: 1024px;
}


/***************** CSS Module Color Values */


/* System */

@value primaryColor: var(--primary-color);
@value maintainColor: var(--maintain-color);
@value manageColor: var(--manage-color);
@value textDark: var(--text-dark);

/* Blues */

@value blue: var(--blue);
@value dreamBlue: var(--dream-blue);
@value mediumElectricBlue: var(--medium-electric-blue);
@value teal: var(--teal);

/* Non-blues */

@value afirefiRed: var(--afirefi-red);
@value peach: var(--peach);

/* Grays */

@value white: var(--white);
@value grayLightSoft: var(--gray-light-soft);
@value grayMediumSoft: var(--gray-medium-soft);
@value grayMediumHard: var(--gray-medium-hard);

/***************** CSS Module System Values */

@value borderRadiusLarge: var(--border-radius-large);
@value borderRadiusSmall: var(--border-radius-small);
@value fontSizeSubTitle: var(--font-size-sub-title);
@value fontSizeLargeText: var(--font-size-large);
@value fontSizeSmallText: var(--font-size-small);
@value fontSizeFinePrint: var(--font-size-fine-print);
@value fontWeightSemiBold: var(--font-weight-semi-bold);
@value fontWeightBold: var(--font-weight-bold);
@value transitionDuration: var(--transition-duration);
@value transitionTiming: var(--transition-timing);