.pageBlockContainer {
    margin-top: 100px;
    margin-left: 280px;
    margin-right: 280px;
    margin-bottom: 100px;
}

.pageBlockContainer p {
    color: #474747;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;
}

.headercontainer {
    padding-top: 65px;
    padding-left: 65px;
}

.subheadercontainer {
    padding-left: 65px;
}

.bodycontainer {
    padding: 65px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.competitionRulesContainer h2 {
    font-family: "PT Serif", serif;
    color: #001559;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
}

.competitionRulesContainer li {
    list-style: none;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
}

.competitionRulesContainer a {
    text-decoration: none;
    color: #001559;
}

.competitionRulesContainer hr {
    margin-top: 10px;
    margin-bottom: 100px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

@media (max-width: 992px) {
    .pageBlockContainer {
        margin-left: 40px;
        margin-right: 40px;
    }
}