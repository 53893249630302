.root {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.root--has-error .button,
.root--has-error .button:active,
.root--has-error .button:hover {
  color: var(--brick-red);
}

.root--is-open .button,
.root--is-open .button:active,
.root--is-open .button:hover {
  color: var(--primary-color);
}

.root--is-open .icon {
  transform: translateY(-50%);
}

.root--is-open .list-wrapper {
  display: block;
  visibility: visible;
}

.button {
  align-items: center;
  border: 1px solid currentColor;
  color: var(--gray-dark-hard);
  display: inline-flex;
  justify-content: center;
  font-size: var(--font-size-small);
  min-width: 13.75rem;
  position: relative;
  width: 100%;
}

.button:hover {
  border-color: currentColor;
  color: var(--primary-color);
}

.button:active {
  border-color: currentColor;
  color: var(--jordy-blue);
}

.icon,
.iconMoreLeft {
  height: 1rem;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: translate ease 0.3s;
  width: 1rem;
}

.iconMoreLeft {
  right: 12px;
}

.icon svg {
  height: 1rem;
  width: 0.5rem;
}

.selectedIcon {
  color: var(--primary-color);
  height: 1.125rem;
  left: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.125rem;
}

.list-wrapper {
  display: none;
  margin-top: 1.625rem;
  visibility: hidden;
  min-width: 100%;
  max-width: 80vw;
  position: relative;
}

.list-shadow {
  background-color: var(--white);
  border-radius: var(--default-border-radius);
  box-shadow: 0 0 8px 2px var(--shadow);
  overflow: hidden;
  width: 100%;
}

.list-arrow {
  height: 1.625rem;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: -1.625rem;
  transform: translateX(-50%);
  width: 2.75rem;
}

.list-arrow::before {
  background-color: var(--white);
  box-shadow: 0 0 8px var(--shadow);
  content: '';
  display: block;
  height: 1.375rem;
  position: relative;
  right: -0.6875rem;
  transform: rotate(45deg);
  top: 0.9375rem;
  width: 1.375rem;
}

.list {
  margin: 0.9375rem 0;
  padding-left: 0;
  max-height: 18.75rem;
  min-width: 100%;
  overflow-y: auto;
}

.list-item {
  align-items: center;
  color: var(--gray-dark-hard);
  display: flex;
  font-size: var(--font-size-small);
  height: 2.375rem;
  justify-content: center;
  cursor: default;
}

.list-item:hover,
.list-item-selected {
  background-color: var(--form-inputs-hover-bg);
}

.list-item-selected {
  font-weight: var(--font-weight-bold);
  position: relative;
}

.list-item-selected svg {
  height: 16px;
  width: 16px;
  padding-right: 4px;
  color: var(--dream-blue);
}
